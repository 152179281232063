import React from 'react';
import azure from '../../img/azure.svg';
import envato from '../../img/envato.svg';
import nodejs from '../../img/nodejs.svg';
import tailwind from '../../img/tailwind.svg';
import github from '../../img/github.svg';

export default function Badges() {
  return (
    <div className="flex-row  justify-center m-5 pb-5 mb-10 lg:pt-24 pt-24">
      <div className="flex gap-10 justify-center" data-aos="fade-left">
        <img src={azure} alt="azure logo" className="w-[20vmin]" />
        <img src={envato} alt="envato logo" className="w-[20vmin]" />
        <img src={nodejs} alt="node logo" className="w-[20vmin]" />
      </div>
      <div className="flex gap-10 justify-center" data-aos="fade-right">
        <img src={github} alt="react logo" className="w-[20vmin]" />
        <img src={tailwind} alt="tailwind logo" className="w-[20vmin] " />
      </div>
    </div>
  );
}
