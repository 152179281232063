import React from 'react';
import ImgCard from './ImgCard';

export default function FlipCard(props) {
  return (
    <>
      <div className="flip-card ">
        <div className="flip-card-inner ">
          <div className=" flip-card-front ">
            <ImgCard img={props.img} class={props.classFront} />
          </div>
          <div className="flip-card-back flex flex-col justify-center">
            {props.imgback ? (
              <ImgCard img={props.imgback} class={props.classBack} />
            ) : (
              <span className="flex h-[100%] flex-col justify-center dark:bg-neutral-950 rounded dark:bg-opacity-80 border-black">
                <h1 className="text-4xl">{props.textback}</h1>
                <p className="text-xl">{props.subtextback}</p>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
