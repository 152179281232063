import React, { forwardRef, useState } from 'react';
import logo from '../../img/logo.png';

const Nav = forwardRef(({ faqRef, mainRef, footerRef, contactRef }, ref) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const scrollToRef = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className="header">
        <div className="container mx-auto">
          <div className="relative flex items-center justify-end">
            <div className="max-w-full px-4 xl:mr-6">
              <a className="header-logo block w-full py-8 " href="/">
                <img
                  alt="logo"
                  src={logo}
                  height="50"
                  width="50"
                  decoding="async"
                  data-nimg="1"
                  className="dark:invert"
                  loading="lazy"
                  stylename="color:transparent"
                  data-aos="fade-right"
                  data-aos-easing="linear"
                  data-aos-duration="750"
                />
              </a>
            </div>
            <div
              className="flex w-full items-center justify-end px-6"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="750"
            >
              <div>
                <button
                  onClick={() => setIsNavOpen((prev) => !prev)}
                  id="navbarToggler"
                  aria-label="Mobile Menu"
                  className="absolute right-4 top-1/2 block translate-y-[-50%] rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden"
                >
                  <span className="relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white  "></span>
                  <span className="relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white  "></span>
                  <span className="relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white  "></span>
                </button>
                <nav
                  id="navbarCollapse"
                  className={`navbar absolute  right-0 z-50 w-[250px] rounded shadow dark:bg-neutral-950 dark:border-neutral-900 py-4 px-6 duration-300 dark:border-body-color dark:bg-dark lg:visible lg:static lg:w-auto lg:border-none lg:!bg-transparent lg:p-0 lg:opacity-100 top-[120%] ${
                    isNavOpen ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <ul className="block lg:flex lg:space-x-12">
                    <li className="">
                      <p
                        className="text-xl flex py-2 text-dark dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                        onClick={() => scrollToRef(mainRef)}
                      >
                        Examples
                      </p>
                    </li>
                    <li className="">
                      <p
                        className="flex py-2 text-xl text-dark dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                        onClick={() => scrollToRef(faqRef)}
                      >
                        FAQ
                      </p>
                    </li>
                    <li className="">
                      <p
                        className="flex py-2 text-xl text-dark dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0"
                        onClick={() => scrollToRef(contactRef)}
                      >
                        Contact
                      </p>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default Nav;
