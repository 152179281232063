import React, { forwardRef } from 'react';
import logo from '../../img/logo.png';
import Card from '../elements/Cards/Card';
import MainGrid from '../elements/MainGrid';
import Badges from '../elements/Badges';

const Main = forwardRef((props, ref) => {
  return (
    <div className="dark:bg-neutral-950 dark:bg-opacity-50 dark:text-white h-100 overflow-hidden">
      <Card
        img={logo}
        imgClass={'dark:invert'}
        title={'Your best IT solution'}
        descryption={
          'I provide a swift and professional approach, carefully tailored to suit each individual project.'
        }
        buttonText={'Contact Me!'}
        buttonLink={'/#'}
      />
      <Badges />

      <h1
        ref={ref}
        className=" right pt-5 mb-5 text-5xl text-center"
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="750"
      >
        Examples
      </h1>
      <MainGrid />
    </div>
  );
});
export default Main;
