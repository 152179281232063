import React, { useState, useEffect } from 'react';

export default function ScrollToTop(props) {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <button
          type="button"
          onClick={backToTop}
          className={`${
            showButton ? `inline-block` : `hidden`
          } hover-green fixed bottom-[40px] right-[40px] p-3 dark:bg-neutral-950 text-white font-medium text-xs leading-tight uppercase rounded-md shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-500 active:shadow-lg transition duration-150 ease-in-out`}
        >
          <i className="py-2 fa fa-2xl fa-backward fa-rotate-90"></i>
        </button>
      )}
    </>
  );
}
