import React, { forwardRef, useState } from 'react';
import BlogPost from '../../data/BlogPost.json';

const Faq = forwardRef((props, ref) => {
  const [selectedPost, setSelectedPost] = useState(null);

  const handleTitleClick = (index) => {
    setSelectedPost(selectedPost === index ? null : index);
  };

  const titleList = BlogPost.posts.map((post, index) => (
    <div key={index} className="flex flex-col m-2">
      <p
        className={`w-full rounded-xl  dark:bg-opacity-80 p-5  cursor-pointer ${
          selectedPost === index ? 'dark:bg-neutral-800' : 'dark:bg-neutral-950'
        }`}
        onClick={() => handleTitleClick(index)}
      >
        {post.title}
      </p>
      {selectedPost === index && (
        <p
          className={`flex min-h-[20vh] rounded-xl items-center  dark:bg-opacity-80 p-2 my-2 ${
            selectedPost === index
              ? 'dark:bg-neutral-800'
              : 'dark:bg-neutral-950'
          }`}
          onClick={() => handleTitleClick(index)}
        >
          {post.content}
        </p>
      )}
    </div>
  ));

  return (
    <div className="dark:text-white text-center ">
      <p ref={ref} className="m-5 p-5 text-4xl">
        Frequently asked questions:
      </p>
      <div className="lg:max-w-[75vw] lg:mx-auto mt-10 ">{titleList}</div>
    </div>
  );
});

export default Faq;
