import React, { useState, forwardRef } from 'react';

const Contact = forwardRef((props, ref) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isMessageValid, setIsMessageValid] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isMessageFocused, setIsMessageFocused] = useState(false);

  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(e.target.value);
    setEmail(e.target.value);
    setIsEmailValid(isValid);
  };

  const handleMessageChange = (e) => {
    const isValid = e.target.value.length >= 25;
    setMessage(e.target.value);
    setIsMessageValid(isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const isSubmitDisabled = !isEmailValid || !isMessageValid;

  return (
    <div className="dark:bg-neutral-950 dark:bg-opacity-50 mt-5 pt-10">
      <div className="max-w-xl mx-auto ">
        <p ref={ref} className=" text-4xl text-center text-white">
          Contact Me!
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className=" text-sm font-medium text-gray-600">Email</label>
            <input
              type="email"
              className={`mt-1 p-2 w-full border rounded ${
                isEmailValid && isEmailFocused
                  ? 'border-green-500'
                  : 'border-red-500'
              }`}
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              onFocus={() => setIsEmailFocused(true)}
              required
            />
            {isEmailFocused && !isEmailValid && (
              <p className="text-red-500 text-sm mt-1">Invalid email address</p>
            )}
          </div>
          {isEmailValid && (
            <div className="mb-4">
              <label className=" text-sm font-medium text-gray-600">
                Message
              </label>
              <textarea
                className={`mt-1 p-2 w-full border rounded ${
                  isMessageValid
                    ? 'border-green-500'
                    : isMessageFocused
                    ? 'border-red-500'
                    : 'border-gray-300'
                }`}
                rows="8"
                placeholder="Enter your message (minimum 25 characters)"
                value={message}
                onChange={handleMessageChange}
                onFocus={() => setIsMessageFocused(true)}
                onBlur={() => setIsMessageFocused(false)}
                required
              />
              {isMessageFocused && !isMessageValid && (
                <p className="text-red-500 text-sm mt-1">
                  Message should be at least 25 characters long
                </p>
              )}
            </div>
          )}
          <button
            type="submit"
            className={`text-white btn hover-green opacity-90 dark:bg-neutral-900 dark:border-neutral-800 text-center px-3 py-2 rounded hover:opacity-100 ${
              isSubmitDisabled && 'hidden'
            }`}
            disabled={isSubmitDisabled}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
});

export default Contact;
