import React from 'react';

export default function Card(props) {
  return (
    <div className="absolute top-72 left-0 right-0 " data-aos="fade-right">
      <div className="shadow-md container mx-auto w-[40vmax] border dark:bg-neutral-950 dark:border-neutral-900 rounded dark:bg-opacity-80 bg-opacity-80">
        <div className="flex  items-center justify-between">
          <div className="flex-1 mx-auto ">
            <img
              src={props.img}
              alt="About Images"
              className={`${props.imgClass} mx-auto`}
            />
          </div>
          <div className="flex-1 mx-auto ">
            <div className="content ">
              <div className="inner ">
                <h1 className="text-xl lg:text-4xl text-center p-2">{props.title}</h1>
                <p className="text-center lg:text-xl p-2">{props.descryption}</p>
                <ul className="contact-address text-center p-1">
                  <li className="">{props.text}</li>
                  <li className="">{props.text2}</li>
                  <li className="">{props.text3}</li>
                </ul>
                <div className="download-button mt--20 text-center p-2">
                  <button
                    className="btn hover-green opacity-90 dark:bg-neutral-900 dark:border-neutral-800 text-center px-3 py-2 rounded hover:opacity-100"
                    href={props.buttonLink}
                  >
                    <p className="">{props.buttonText}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
