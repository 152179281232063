import React from 'react';

export default function ImgCard(props) {
  return (
    <div className="dark:bg-neutral-950 rounded dark:bg-opacity-80 border-black h-[100%] ">
      <img
        src={props.img}
        alt="About Images"
        className={`h-[100%] ${props.class} rounded`}
      />
    </div>
  );
}
