import ReactDOM from 'react-dom/client';
import App from './App';
import './css/global.css';
import './css/neon.css';
import './css/flipcard.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// ..
AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);
