import React from 'react';
import FlipCard from './Cards/FlipCard';
import tnce from '../../img/tnce.png';
import logo from '../../img/logo.png';

export default function MainGrid() {
  return (
    <div className="mx-auto lg:grid lg:grid-cols-3 lg:grid-rows-3 lg:w-[75vw] pb-10">
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-down-right"
        data-aos-duration="1500"
      >
        <FlipCard
          img={tnce}
          classFront={'bg-black mx-auto'}
          classBack={''}
          textback={"T's n C's"}
          subtextback={'Terms and conditions simplified.'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-down"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-up-left"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
      <div
        className="h-[35vmax] lg:h-80 flex justify-center "
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <FlipCard
          img={logo}
          imgback={logo}
          classBack={'dark:invert mx-auto'}
          classFront={'dark:invert mx-auto'}
        />
      </div>
    </div>
  );
}
