import React from 'react';

export default function Head() {
  return (
    <div className="h-[45vh]">
      
      <div
        className="neon text-center"
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="2500"
      >
        <b
          className=""
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          Ve<span>z</span>z<span>C</span>o
        </b>
      </div>
    </div>
  );
}
